.app-timeline {
  margin: govuk-spacing(6) 0 0;
}

.app-timeline__item {
  margin: 0;
  padding-bottom: govuk-spacing(6);
  padding-left: govuk-spacing(5);
  position: relative;

  &::before {
    background-color: $govuk-border-colour;
    content: "";
    height: 5px;
    left: 0;
    position: absolute;
    top: govuk-spacing(2);
    width: 15px;
  }

  &::after {
    background-color: $govuk-border-colour;
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: govuk-spacing(2);
    width: 5px;
  }

  &:last-child {
    padding-bottom: 0;

    &::after {
      display: none;
    }
  }
}

.app-timeline__title {
  display: block;
  font-weight: $govuk-font-weight-bold;
  margin-bottom: govuk-spacing(1);
}

.app-timeline__author,
.app-timeline__date {
  display: block;
}

.app-timeline__info {
  margin-top: 0;
  margin-bottom: 0;
}

.app-timeline__body {
  display: block;
}
