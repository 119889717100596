@import "accessible-autocomplete";

// Override Arial default font
.autocomplete__hint,
.autocomplete__input,
.autocomplete__option {
  @include govuk-typography-common;
}

// Override margin added by HMPO list styles
.autocomplete__hint,
.autocomplete__option {
  margin-bottom: 0;
}

.autocomplete__input {
  height: govuk-px-to-rem(40px);
}
