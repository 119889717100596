[data-a11y-dialog-native] > :first-child {
  display: none;
}

body:not(.js-enabled) .dialog-overlay,
.dialog[aria-hidden="true"] .dialog-overlay {
  display: none;
}

dialog,
dialog[open] {
  display: block;
}

.dialog[aria-hidden="true"] {
  display: none;
}

.dialog-container[aria-hidden="true"] {
  display: none;
}

.timeout_dialog_titlebar {
  padding: 8px 15px 10px;
  color: govuk-colour("white");
  background: govuk-colour("black");
  margin: -1em -1em 2em;

  & *:focus {
    outline: 3px solid govuk-colour("yellow");
    outline-offset: 0;
  }

  & .timeout_dialog_button {
    line-height: 1.3;
    position: absolute;
    top: 0;
    right: 0;
    width: 44px;
    height: 44px;
    border: 0;
    color: govuk-colour("white");
    background: none;
    cursor: pointer;
    font-family: nta, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 700;
    font-size: 24px;
  }
}

.dialog-overlay {
  z-index: 2;
  background-color: rgba(0, 0, 0, .66);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

dialog::backdrop {
  background-color: rgba(0, 0, 0, .66);
}

.dialog-content {
  background-color: govuk-colour("white");
  border: 2px solid govuk-colour("black");
  border-radius: 2px;
  z-index: 3;
  position: fixed;
  padding: 1em;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  margin: 0;
  max-width: 90%;
  width: 600px;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes appear {
  from {
    transform: translate(-50%, -40%);
    opacity: 0;
  }

  to {
    transform: translate(-50%, -50%);
    opacity: 1;
  }
}

.js-enabled .dialog:not([aria-hidden="true"]) > .dialog-overlay {
  animation: fade-in 200ms 1 both;
}

.js-enabled .dialog > .dialog-overlay {
  opacity: 0;
}

.js-enabled .dialog:not([aria-hidden="true"]) > .dialog-content {
  animation: appear 200ms 200ms 1 both;
}

@media screen and (min-width: 700px) {
  .dialog-content {
    padding: 2em;
  }
}

.dialog h1 {
  margin: 0;
  font-size: 1.25em;
}

.dialog-close {
  position: absolute;
  top: .5em;
  right: .5em;
  border: 0;
  padding: 0;
  background-color: transparent;
  font-weight: bold;
  font-size: 1.25em;
  width: 1.2em;
  height: 1.2em;
  text-align: center;
  cursor: pointer;
  transition: .15s;
}

@media screen and (min-width: 700px) {
  .dialog-close {
    top: 1em;
    right: 1em;
  }
}

@media screen and (min-width: 700px) {
  .timeout_dialog_titlebar {
    margin: -2em -2em 2em;
  }
}
