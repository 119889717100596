// stylelint-disable font-family-no-duplicate-names

.app-code {
  background: govuk-colour("light-grey");
  border: 1px solid $govuk-border-colour;
  padding: govuk-spacing(4);
  max-width: 100%;
  font-family: monospace, monospace;
  @include govuk-typography-responsive($size: 14);
  white-space: break-spaces;
}
