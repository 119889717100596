.govuk-accordion--compact {
  .govuk-accordion__show-all,
  .govuk-accordion__section-toggle-text {
    @include govuk-visually-hidden;
  }

  .govuk-accordion__section-heading-text {
    margin-bottom: 0;
    color: $govuk-link-colour;
  }

  .govuk-accordion__section-toggle {
    @include govuk-font(16);
    position: absolute;
    top: 16px;
    left: govuk-spacing(2);
    margin: 0;
    padding: 0;
  }

  .govuk-accordion__section-button {
    @include govuk-font(19);
    padding-bottom: govuk-spacing(1);
    padding-top: govuk-spacing(3);
    padding-left: govuk-spacing(7);
    position: relative;

    &:hover {
      .govuk-accordion__section-heading-text-focus {
        color: $govuk-link-hover-colour;
        box-shadow: 0 0, 0 4px $govuk-link-hover-colour;
      }
    }
  }

  .govuk-accordion__section-content {
    padding-left: govuk-spacing(2);
    padding-right: govuk-spacing(2);
  }
}
