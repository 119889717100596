.app-page-contents {
  position: relative;
}

.app-page-contents__title {
  @include govuk-font(16);
  margin-bottom: 0;
}

.app-page-contents__item {
  @include govuk-font(16);
  padding: govuk-spacing(2) 5px 0 25px;
  margin: 0;
}

.app-page-contents__symbol::before {
  content: "—";
  position: absolute;
  left: 0;
  width: 20px;
  overflow: hidden;
}
