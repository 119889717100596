// Hide persisted :focus outline
// See SkipLink.prototype.focusLinkedElement
.app-search__results {
  outline: none;
}

.govuk-grid-row + .app-search {
  margin-top: govuk-spacing(4);
}

@include govuk-media-query($from: tablet) {
  .govuk-grid-row + .app-search {
    margin-top: 0;
  }

  // Move spacing above headings (for skip link)
  .app-search__filters,
  .app-search__results {
    padding-top: govuk-spacing(2);
  }
}

@include govuk-media-query($from: desktop) {
  // Move spacing above headings (for skip link)
  .app-search__filters,
  .app-search__results {
    padding-top: govuk-spacing(4);
  }
}
