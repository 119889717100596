// stylelint-disable declaration-no-important

// Allow tables to wrap content to fit
.app-table--overflow-wrap {
  table-layout: fixed;

  .govuk-\!-width-three-quarters {
    width: 75% !important;
  }

  .govuk-\!-width-two-thirds {
    width: 66.66% !important;
  }

  .govuk-\!-width-one-half {
    width: 50% !important;
  }

  .govuk-\!-width-one-third {
    width: 33.33% !important;
  }

  .govuk-\!-width-one-quarter {
    width: 25% !important;
  }
}
