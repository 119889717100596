@include govuk-media-query($from: desktop) {
  .app-hide-desktop {
    display: none;
  }

  .app-text-align-center-desktop {
    text-align: center;
  }
}

@include govuk-media-query($until: tablet) {
  .app-display-inline-block-mobile {
    display: inline-block;
  }

  // stylelint-disable declaration-no-important
  .app-margin-left-4-mobile {
    margin-left: 15px !important;
  }
  // stylelint-enable declaration-no-important

  .app-top-minus-7-mobile {
    position: relative;
    top: -7px;
  }
}
