.app-\!-overflow-wrap {
  // Automatic wrapping for unbreakable text (e.g. URLs)
  word-wrap: break-word; // Fallback for older browsers only
  overflow-wrap: break-word;
}

.app-address {
  @include govuk-font(19);
  font-style: inherit;
}

.govuk-panel--confirmation .govuk-panel__title {
  color: govuk-colour("white");
}

.govuk-caption {
  color: $govuk-secondary-text-colour;
}

.govuk-tag {
  white-space: nowrap;
}

.govuk-link--warning {
  color: $govuk-error-colour;

  &:link,
  &:visited {
    color: $govuk-error-colour;
  }

  &:hover {
    color: govuk-shade($govuk-error-colour, 20%)
  }
}
