.app-share-links {
  margin: govuk-spacing(0) 0 0;
  list-style: none;
}

.app-share-links__item {
  @include govuk-font(16);
  padding: govuk-spacing(1) 0 0 govuk-spacing(6);
  margin: 0 0 govuk-spacing(4);
  position: relative;
  display: inline-block;
  box-sizing: border-box;
}

.app-share-links__link {
  margin-right: govuk-spacing(5);
  text-decoration: none;
  font-weight: $govuk-font-weight-bold;
}

.app-share-links__link-icon {
  position: absolute;
  top: 0;
  left: 0;
}
