.app-grid-column-one-fifth {
  width: 100%;
  padding: 0 15px;
}

@include govuk-media-query($from: desktop) {
  .app-grid-column-one-fifth {
    width: 15.4%;
    float: left;
  }
}

.app-reading-width {
  max-width: 39.5em; // 630px
}

.app-\!-counteract-wrapper-top {
  margin-top: -(govuk-spacing(3));

  @include govuk-media-query($from: tablet) {
    margin-top: -(govuk-spacing(6));
  }
}
